// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `vd-charts h4 {
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #01222a;
}
vd-charts .chart-legends .chart-legend {
  border-radius: 10px;
  min-width: 125px;
  min-height: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
  color: #ffffff;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/vd-charts/vd-charts/vd-charts.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AADR;AAKQ;EACI,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,cAAA;EACA,qBAAA;AAHZ","sourcesContent":["@import \"~themes/variables.scss\";\nvd-charts {\r\n    h4{\r\n        font-family: Roboto;\r\n        font-size: 1rem;\r\n        font-weight: 500;\r\n        font-style: normal;\r\n        font-stretch: normal;\r\n        line-height: normal;\r\n        letter-spacing: normal;\r\n        color: #01222a;\r\n    }\r\n\r\n    .chart-legends{\r\n        .chart-legend {\r\n            border-radius: 10px;\r\n            min-width: 125px;\r\n            min-height: 20px;\r\n            margin-bottom: 5px;\r\n            margin-right: 5px;\r\n            padding: 5px;\r\n            color: #ffffff;\r\n            display: inline-block;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
