// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/air-datepicker/air-datepicker.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `vd-date-picker {
  position: relative;
}
vd-date-picker .air-datepicker.-disabled-range- {
  --adp-cell-background-color-in-range: #eeeeee;
  --adp-cell-background-color-selected: #d0d0d0;
}
vd-date-picker .air-datepicker {
  --adp-cell-background-color-selected: hsl(49, 100%, 47%);
  --adp-cell-background-color-selected-hover: hsla(49, 100%, 57%, 0.5);
}
vd-date-picker .air-datepicker .-focus-week- {
  background-color: hsla(49, 100%, 47%, 0.2);
}
vd-date-picker #datepicker {
  transition: all 0.1s ease-in-out;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
vd-date-picker #datepicker > div {
  width: 100%;
}
vd-date-picker #datepicker.active {
  height: 272px;
}
vd-date-picker #datepicker.hidden {
  height: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/vd-date-picker/vd-date-picker.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AAAJ;AAEI;EACI,6CAAA;EACA,6CAAA;AAAR;AAGI;EACI,wDAAA;EACA,oEAAA;AADR;AAGQ;EACI,0CAAA;AADZ;AAKI;EACI,gCAAA;EAEA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;EACA,iDAAA;AAJR;AAMQ;EACI,WAAA;AAJZ;AAOQ;EACI,aAAA;AALZ;AAQQ;EACI,SAAA;AANZ","sourcesContent":["@import \"~themes/variables.scss\";\n@import \"/node_modules/air-datepicker/air-datepicker.css\";\r\nvd-date-picker {\r\n    position: relative;\r\n\r\n    .air-datepicker.-disabled-range- {\r\n        --adp-cell-background-color-in-range: #eeeeee;\r\n        --adp-cell-background-color-selected: #d0d0d0;\r\n    }\r\n\r\n    .air-datepicker {\r\n        --adp-cell-background-color-selected: hsl(49, 100%, 47%);\r\n        --adp-cell-background-color-selected-hover: hsla(49, 100%, 57%, 0.5);\r\n\r\n        .-focus-week- {\r\n            background-color: hsla(49, 100%, 47%, 0.2);\r\n        }\r\n    }\r\n\r\n    #datepicker {\r\n        transition: all 0.1s ease-in-out;\r\n\r\n        position: absolute;\r\n        top: 40px;\r\n        left: 0;\r\n        width: 100%;\r\n        z-index: 10;\r\n        overflow: hidden;\r\n        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\r\n\r\n        > div {\r\n            width: 100%;\r\n        }\r\n\r\n        &.active {\r\n            height: 272px;\r\n        }\r\n\r\n        &.hidden {\r\n            height: 0;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
