// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #F8D7DA;
    color: #6A1119;
    padding: 5px 20px;
    text-align: center;
    height: 64px;
  }
  .ie-message-manual-margin {
    width: 100%;
    height: 64px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ie-message/ie-message.scss"],"names":[],"mappings":"AACA;EACI;IACI,aAAA;IACA,uBAAA;IACA,mBAAA;IACA,WAAA;IACA,mBAAA;IACA,cAAA;IACA,iBAAA;IACA,kBAAA;IACA,YAAA;EAAN;EAGE;IACI,WAAA;IACA,YAAA;EADN;AACF","sourcesContent":["@import \"~themes/variables.scss\";\n@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {    \r\n    .ie-message{\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        width: 100%;\r\n        background: #F8D7DA;\r\n        color: #6A1119;\r\n        padding: 5px 20px;\r\n        text-align: center;\r\n        height: 64px;\r\n    }\r\n\r\n    .ie-message-manual-margin {\r\n        width: 100%;\r\n        height: 64px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
