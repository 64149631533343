// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `vd-slider-range {
  display: flex;
  flex-direction: column;
}
vd-slider-range .inputs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
vd-slider-range .inputs .mdc-text-field--filled {
  height: 24px;
  padding: 0 12px;
}
vd-slider-range .inputs .mdc-text-field--filled > input {
  font-size: 0.8rem;
}
vd-slider-range mdc-segmented-button {
  display: inline-flex !important;
}
vd-slider-range mdc-segmented-button i {
  font-size: 14px;
}
vd-slider-range mdc-segmented-button button {
  min-width: 28px !important;
  max-width: 28px !important;
  min-height: 28px !important;
  max-height: 28px !important;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/vd-slider-range/vd-slider-range.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;AAEI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR;AAEQ;EACI,YAAA;EACA,eAAA;AAAZ;AAEY;EACI,iBAAA;AAAhB;AAKI;EACI,+BAAA;AAHR;AAKQ;EACI,eAAA;AAHZ;AAMQ;EACI,0BAAA;EACA,0BAAA;EACA,2BAAA;EACA,2BAAA;EACA,qBAAA;AAJZ","sourcesContent":["@import \"~themes/variables.scss\";\nvd-slider-range {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    .inputs {\r\n        width: 100%;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: space-between;\r\n\r\n        .mdc-text-field--filled {\r\n            height: 24px;\r\n            padding: 0 12px;\r\n\r\n            > input {\r\n                font-size: 0.8rem;\r\n            }\r\n        }\r\n    }\r\n\r\n    mdc-segmented-button {\r\n        display: inline-flex !important;\r\n\r\n        i {\r\n            font-size: 14px;\r\n        }\r\n\r\n        button {\r\n            min-width: 28px !important;\r\n            max-width: 28px !important;\r\n            min-height: 28px !important;\r\n            max-height: 28px !important;\r\n            padding: 0 !important;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
